export class WbProduct {
  constructor() {
    this.init();
  }

  init() {
    this.bindFormElement();
    document.addEventListener('rebuild-form', () => {
      this.bindFormElement();
    }, false);
  }

  bindFormElement() {
    this.initVariantSelection();
    this.initCustomHdfColor();
    this.initCustomSize();
    this.initCustomPrice();
    this.initOnePagerCustomColor();
  }

  initVariantSelection() {
    const productForm = document.querySelectorAll('.wb-product-variant-form__form');
    const insertionEssence = document.querySelector('.wb-product-variant-form__select[name="options[insertionEssence]"]');
    insertionEssence?.addEventListener('change', () => {
       document.querySelector('.wb-product-variant-form__select[name="options[insertionFinition]"]').value = '';
      });

    productForm.forEach((formElement) => {
      const { isVariantAutoselected } = formElement.dataset;
      if (isVariantAutoselected) {
        this.validateSelection(formElement);
      }

      formElement?.addEventListener('change', () => {
        this.validateSelection(formElement);
      });
    });
  }

  validateSelection(formElement) {
    const formDataOnLoad = new FormData(formElement);
    const selectedData = Object.fromEntries(formDataOnLoad.entries());
    const submitBtn = formElement?.querySelector('.wb-product-variant-form__submit');
    submitBtn?.classList.add('btn--disabled');
    const formParent = formElement.closest('.wb-product-details__inner');
    const productParent = formElement.closest('.wb-product-details');
    formParent?.querySelectorAll('.wb-product-variant-form__variant')?.forEach((variant) => {
      const {
        finition,
        grain,
        hdfColor,
        hdfInterior,
        hdfSideFinish,
        shelfSize,
        gripOrientation,
        hingePosition,
        accessoriesColor,
        accessoriesSize,
        attributeDesign,
        application,
        dekton,
        glass,
        insertion,
        sampleDesign,
        sampleColor,
        sampleFinish,
        sampleHdfFinish,
        sampleLaminateColor,
        sampleLaminateDesign,
        samplePolymerFinish,
        sampleWoodFinish,
        sampleCollection,
        open,
        polymer,
        productMainImage,
        variantImage,
        sku,
        id,
        price,
        pricePerFeet,
        minimumPrice,
        stock,
        cut,
        type,
        laminate,
      } = variant.dataset;


      if (selectedData.attributeDesign && typeof (formParent.querySelector('.wb-product-variant-form__select[name="open"]')) != 'undefined'
        && formParent.querySelector('.wb-product-variant-form__select[name="open"]') != null  && (selectedData.attributeDesign === 'grip' || selectedData.attributeDesign === 'pull' || selectedData.attributeDesign === 'round' || selectedData.attributeDesign === 'square'  || selectedData.attributeDesign === 'rond' || selectedData.attributeDesign === 'carre')) {
        if (selectedData.gripOrientation == 'side' || selectedData.application === 'pull-out' || selectedData.application === 'garbage-drawer') {
          formParent.querySelector('.wb-product-variant-form__select[name="open"]')?.classList?.add('--disabled');
          formParent.querySelector('.wb-product-variant-form__label[for="open"]')?.classList?.add('--disabled');
          formParent.querySelector('.wb-product-variant-form__select[name="open"]').value = '';
          selectedData.open = '';
        } else if (selectedData.gripOrientation !== 'side' 
          && selectedData.application !== 'pull-out' && selectedData.application !== 'garbage-drawer' && formParent.querySelector('.wb-product-variant-form__select[name="open"]').value === '') {
          formParent.querySelector('.wb-product-variant-form__select[name="open"]')?.classList?.remove('--disabled');
          formParent.querySelector('.wb-product-variant-form__label[for="open"]')?.classList?.remove('--disabled');
          formParent.querySelector('.wb-product-variant-form__select[name="open"]').selectedIndex = 0;
          selectedData.open = formParent.querySelector('.wb-product-variant-form__select[name="open"]')?.value;
        }

      }
      if (
        (selectedData.finition && selectedData.finition !== finition) ||
        (selectedData.variantSampleDesign && selectedData.variantSampleDesign !== sampleDesign) ||
        (selectedData.variantSampleColor && selectedData.variantSampleColor !== sampleColor) ||
        (selectedData.variantSampleFinish && selectedData.variantSampleFinish !== sampleFinish) ||
        (selectedData.variantSampleHdfFinish && selectedData.variantSampleHdfFinish !== sampleHdfFinish) ||
        (selectedData.sampleLaminateColor && selectedData.sampleLaminateColor !== sampleLaminateColor) ||
        (selectedData.sampleLaminateDesign && selectedData.sampleLaminateDesign !== sampleLaminateDesign) ||
        (selectedData.variantSamplePolymerFinish && selectedData.variantSamplePolymerFinish !== samplePolymerFinish) ||
        (selectedData.variantSampleWoodFinish && selectedData.variantSampleWoodFinish !== sampleWoodFinish) ||
        (selectedData.variantSampleCollection && selectedData.variantSampleCollection !== sampleCollection) ||
        (selectedData.hdfColor && selectedData.hdfColor !== hdfColor) ||
        (selectedData.hdfInterior && selectedData.hdfInterior !== hdfInterior) ||
        (selectedData.hdfSideFinish && selectedData.hdfSideFinish !== hdfSideFinish) ||
        (selectedData.accessoriesColor && selectedData.accessoriesColor !== accessoriesColor) ||
        (selectedData.shelfSize && selectedData.shelfSize !== shelfSize) ||
        (selectedData.accessoriesSize && selectedData.accessoriesSize !== accessoriesSize) ||
        (selectedData.gripOrientation && selectedData.gripOrientation !== gripOrientation) ||
        (selectedData.attributeDesign && selectedData.attributeDesign !== attributeDesign) ||
        (selectedData.application && selectedData.application !== application) ||
        (selectedData.dekton && selectedData.dekton !== dekton) ||
        (selectedData.insertion && selectedData.insertion !== insertion) ||
        (selectedData.open && selectedData.open !== open && selectedData.gripOrientation  && (selectedData.gripOrientation !== 'side' &&  selectedData.application !== 'pull-out' && selectedData.application !== 'garbage-drawer')  && (selectedData.attributeDesign === 'grip' || selectedData.attributeDesign === 'pull' || selectedData.attributeDesign === 'rond' || selectedData.attributeDesign === 'carre' || selectedData.attributeDesign === 'round' || selectedData.attributeDesign === 'square')) ||
        (selectedData.polymer && selectedData.polymer !== polymer) ||
        (selectedData.hingePosition && selectedData.hingePosition !== hingePosition) ||
        (selectedData.variantLaminate && selectedData.variantLaminate !== laminate) ||
        (selectedData.variantCut && selectedData.variantCut !== '' && selectedData.variantCut !== cut) ||
        (selectedData.variantType && selectedData.variantType !== '' && selectedData.variantType !== type)
      ) {
        return false;
      }

      if (selectedData.glass && selectedData.glass !== 'no-glass') {
        formParent?.querySelector('.wb-product-variant-form__select[name="grain"]')?.classList?.add('--disabled');
        formParent?.querySelector('.wb-product-variant-form__label[for="grain"]')?.classList?.add('--disabled');
      } else if (selectedData.glass && selectedData.glass === 'no-glass') {
        formParent?.querySelector('.wb-product-variant-form__select[name="grain"]')?.classList?.remove('--disabled');
        formParent?.querySelector('.wb-product-variant-form__label[for="grain"]')?.classList?.remove('--disabled');
      }

      if (selectedData.glass && selectedData.glass === 'no-glass' && glass === 'no-glass') {
        if (selectedData.grain && selectedData.grain !== grain) {
          return false;
        }
      } else if (selectedData.glass && selectedData.glass !== glass) {
        return false;
      } else if (!selectedData.glass && selectedData.grain && selectedData.grain !== grain) {
        return false;
      }

      if (selectedData['options[insertionEssence]']) {
        if (selectedData['options[insertionEssence]'] !== 'hdf') {
          // Remove all data from HDF Color list.
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]').value = '';
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]')?.classList?.add('d-none');
          formParent.querySelector('.wb-product-variant-form__label[for="options[insertionHDFColor]"]')?.classList?.add('d-none');
          formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor').classList?.add('d-none');
          formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor').value = '';

          if (selectedData['options[insertionEssence]'] == 'laminate') {
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--laminate')?.forEach((item) => {
          item.classList?.remove('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--wood')?.forEach((item) => {
          item.classList?.add('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--polymer')?.forEach((item) => {
          item.classList?.add('d-none');
        });

          }else if (selectedData['options[insertionEssence]'] == 'polymer') {
            formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--laminate')?.forEach((item) => {
          item.classList?.add('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--wood')?.forEach((item) => {
          item.classList?.add('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--polymer')?.forEach((item) => {
          item.classList?.remove('d-none');
        });
      }
      else{
            formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--laminate')?.forEach((item) => {
          item.classList?.add('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--wood')?.forEach((item) => {
          item.classList?.remove('d-none');
        });
             formParent.querySelectorAll('.wb-product-variant-form__select[name="options[insertionFinition]"] .insertionFinition--polymer')?.forEach((item) => {
          item.classList?.add('d-none');
        });
          }

          // Add Finition back to the list
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionFinition]"]')?.classList?.remove('d-none');
          formParent.querySelector('.wb-product-variant-form__label[for="options[insertionFinition]"]')?.classList?.remove('d-none');

          selectedData['options[insertionHDFColor]'] = '';
        } else {
          // Add HDF Color list.
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]')?.classList?.remove('d-none');
          formParent.querySelector('.wb-product-variant-form__label[for="options[insertionHDFColor]"]')?.classList?.remove('d-none');

          // Add Finition back to the list
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionFinition]"]')?.classList?.add('d-none');
          formParent.querySelector('.wb-product-variant-form__label[for="options[insertionFinition]"]')?.classList?.add('d-none');
          formParent.querySelector('.wb-product-variant-form__select[name="options[insertionFinition]"]').value = '';

          if (typeof (formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]')) != 'undefined'
            && formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]') != null
            && formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]').value === '') {
            formParent.querySelector('.wb-product-variant-form__select[name="options[insertionHDFColor]"]').value = 'custom';
            formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor').classList?.remove('d-none');
            selectedData['options[insertionHDFColor]'] = 'custom';
          }
        }

        if (selectedData['options[insertionHDFColor]']) {
          if (selectedData['options[insertionHDFColor]'] !== 'custom') {
            formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor').classList?.add('d-none');
            formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor input').value = '';
          } else if (selectedData['options[insertionHDFColor]'] === 'custom') {
            formParent.querySelector('.wb-product-variant-form__hdfInsertionOtherColor').classList?.remove('d-none');
          }
        }
      }

      if (id && sku) {

        if(stock > 0) {
          submitBtn?.classList.remove('btn--disabled');
        }

        formParent
          .querySelector('.wb-product-variant-form__invalid-object')
          ?.classList.add('d-none');
        formParent.querySelector('[name="purchasableId"]')?.setAttribute('value', id);
        formParent.querySelectorAll('[name="elementId"]')?.forEach((item) => {
          item.setAttribute('value', id);
        });
        if (formParent.querySelector('.wb-product-heading__price')) {
          if (minimumPrice && pricePerFeet) {
            const sqinValue = parseFloat(formParent.querySelector('input[name="sqin"]')?.value);
            const price = parseFloat(pricePerFeet);
            const priceMinimum = parseFloat(minimumPrice);
            const priceToDisplay = (sqinValue * price) + priceMinimum;
            formParent.querySelector('.wb-product-heading__price').innerHTML = `${priceToDisplay.toFixed(2)} $`;
          } else {
            formParent.querySelector('.wb-product-heading__price').innerHTML = `${price}`;
          }
        }
        if (productParent?.querySelector('.wb-product-details__image') && (variantImage || productMainImage)) {
          productParent
            .querySelector('.wb-product-details__image')
            ?.setAttribute('src', variantImage || productMainImage);
          productParent
            .querySelector('.wb-product-details__image-section')
            ?.classList.remove('wb-product-details__image-section--empty');
        }
      }
    });
    if (submitBtn.classList.contains('btn--disabled')) {
      formParent
        .querySelector('.wb-product-variant-form__invalid-object')
        ?.classList.remove('d-none');
    }
  }

  initCustomHdfColor() {
    const productList = document.querySelectorAll('.wb-product-details');
    productList?.forEach((product) => {
      product?.querySelectorAll('.hdfColor')?.forEach((item) => {
        item.addEventListener('change', (e) => {
          if (e.currentTarget.id !== 'hdfColor') {
            return;
          }
          if (e.currentTarget.value === 'custom') {
            e.currentTarget.parentNode.querySelector('.wb-product-details__hdfOtherColor')?.classList.remove('d-none');
          } else {
            e.currentTarget.parentNode.querySelector('.wb-product-details__hdfOtherColor')?.classList.add('d-none');
          }
        });
      });
    });

    document.querySelector('#hdfColor')?.dispatchEvent(new Event('change'));
  }

  initOnePagerCustomColor() {
    const customField = document.querySelector('#input-customcolorname');
    const onePagerCustomColor = document.querySelector('#input-hdfFinition-custom');
    const allHdfFinition = document.querySelectorAll('.hdfFinition');

    allHdfFinition?.forEach((color) => {
      color?.addEventListener('click', () => {
        if (customField !== color) {
          customField?.classList.add('d-none');
        }
      });
    });

    onePagerCustomColor?.addEventListener('click', () => {
      customField?.classList.remove('d-none');
    });
  }

  initCustomSize() {
    const productDetailList = document.querySelectorAll('.wb-product-details');
    productDetailList?.forEach((product) => {
      product?.querySelectorAll('.custom-size')?.forEach((item) => {
        item.addEventListener('change', () => {
          const widthEightId = product.querySelector('select[name=\'options[width_eighth]\']');
          const lengthEightId = product.querySelector('select[name=\'options[length_eighth]\']');
          const lengthRound = parseInt(
            product.querySelector('select[name=\'options[length_inches]\']')?.value,
            10
          );
          const lengthEighth =
            parseInt(lengthEightId.value, 10) / 8;
          const lengthTotal = lengthRound + lengthEighth;
          const widthRound = parseInt(
            product.querySelector('select[name=\'options[width_inches]\']')?.value,
            10
          );
          const widthEighth = parseInt(widthEightId.value, 10) / 8;
          const lengthWidth = widthRound + widthEighth;
          const totalSqIn = (lengthTotal * lengthWidth) / 144;
          const qtyField = product?.querySelector('.wb-product-details__sq-in input[name="sqin"]');
          qtyField.value = totalSqIn;
          this.validateSelection(product?.querySelector('.wb-product-variant-form__form'));

          const maxWidthInch = parseInt(product?.querySelector('[name="options[width_inches]"]').options[product?.querySelector('[name="options[width_inches]"]').options.length - 1].value, 10) || 36;
          const maxLengthInch = parseInt(product?.querySelector('[name="options[length_inches]"]').options[product?.querySelector('[name="options[length_inches]"]').options.length - 1].value, 10) || 96;

          if (widthRound === maxWidthInch) {
            widthEightId?.classList.add('d-none');
            widthEightId.selectedIndex = 0;
          } else {
            widthEightId?.classList.remove('d-none');
          }
          if (lengthRound === maxLengthInch) {
            lengthEightId?.classList.add('d-none');
            lengthEightId.selectedIndex = 0;
          } else {
            lengthEightId?.classList.remove('d-none');
          }
          product.querySelectorAll('.wb-product-wishlist__form')?.forEach(wishlist => {
            wishlist
              .querySelector('input[name="options[length_inches]"]')
              ?.setAttribute(
                'value',
                product.querySelector('select[name=\'options[length_inches]\']').value
              );
            wishlist
              .querySelector('input[name="options[length_eighth]"]')
              ?.setAttribute(
                'value',
                product.querySelector('select[name=\'options[length_eighth]\']').value
              );
            wishlist
              .querySelector('input[name="options[width_inches]"]')
              ?.setAttribute(
                'value',
                product.querySelector('select[name=\'options[width_inches]\']').value
              );
            wishlist
              ?.querySelector('input[name="options[width_eighth]"]')
              ?.setAttribute(
                'value',
                product.querySelector('select[name=\'options[width_eighth]\']').value
              );
          });
        });
      });
    });

    document.querySelector('.custom-size')?.dispatchEvent(new Event('change'));
  }

  initCustomPrice() {
    const productDetailList = document.querySelectorAll('.wb-product-details');
    productDetailList?.forEach((product) => {
      product?.querySelectorAll('.custom-price')?.forEach((item) => {
        item.addEventListener('change', (e) => {
          product.querySelectorAll('.wb-product-wishlist__form')?.forEach(wishlist => {
            wishlist
              .querySelector('input[name="options[custom_price]"]')
              ?.setAttribute(
                'value',
                e?.currentTarget?.value
              );
          });
        });
      });


      product?.querySelectorAll('.note')?.forEach((note) => {
        note.addEventListener('change', (e) => {
          product.querySelectorAll('.wb-product-wishlist__form')?.forEach(wishlist => {
            wishlist
              .querySelector('input[name="options[note]"]')
              ?.setAttribute(
                'value',
                e?.currentTarget?.value
              );
          });
        });
      });


    });
  }
}
