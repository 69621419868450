export class BackToTop {
  constructor() {
    this.init();
  }

  init() {
    const _this = this;

    return new Promise((resolve) => {
      document
        .querySelector('body')
        ._addCustomEventListener(
          'click',
          '.back-top',
          _this.scrollToTop.bind(_this)
        );

      resolve();
    });
  }

  scrollToTop() {
    WebitScrollTo.scrollTo({ element: 'body' });
  }
}
