export class WbProductFilters {
  constructor() {
    this.init();
  }

  init() {
    this.filterCollapse();
  }
  

  filterCollapse() {
    document.querySelectorAll('.wb-filter-collapse')?.forEach(collapseElement => {
      collapseElement.querySelector('.wb-filter-collapse__toggle')?.addEventListener('click', () => {
        collapseElement.querySelector('.wb-filter-collapse__inner')?._slideToggle();
        collapseElement?._toggleAriaExpanded();
      });
    });
  }
}
