import { gsap } from 'gsap';
import { TrapFocus } from './TrapFocus';
import { throttle } from 'lodash';

export class Masthead {
  constructor() {
    this.lastScroll = 0;
    this.TrapFocus = new TrapFocus({
      ele: document.querySelector('#masthead'),
      activeClass: 'wb-header--open'
    });
    this.init();
  }

  init() {
    this.resize(true);
    this.bindListeners();
  }

  bindListeners() {
    this.addScrollListener();
    this.addBurgerListener();
    this.addOverlayListener();
    this.addSearchbarListener();
    this.addSubmenuListener();
    this.addResizeListener();
  }

  addSubmenuListener() {
    const _this = this;
    document
      .querySelector('body')
      ._addCustomEventListener(
        'click',
        '.wb-menu__item.wb-menu__item--has-children',
        _this.toggleSubMenu.bind(_this)
      );
  }

  addResizeListener() {
    const _this = this;
    window.addEventListener('resize', throttle(_this.resize.bind(_this), 200));
  }

  addBurgerListener() {
    const _this = this;
    document
      .querySelector('body')
      ._addCustomEventListener('click', '#wb-burger', _this.toggleMenu.bind(_this));
  }

  addOverlayListener() {
    const _this = this;
    document
      .querySelector('body')
      ._addCustomEventListener('click', '#masthead .wb-overlay', _this.toggleOverlay.bind(_this));
  }

  addSearchbarListener() {
    const _this = this;
    document
      .querySelector('body')
      ._addCustomEventListener('click', '#masthead .wb-header__account-link__searchbar', _this.openSearchbar.bind(_this));
    document
      .querySelector('body')
      ._addCustomEventListener('click', '#masthead .wb-header__searchbar__close', _this.closeSearchbar.bind(_this));
  }

  addScrollListener() {
    const _this = this;

    document.addEventListener('scroll', e => {
      const headerAlert = document.querySelector('#wb-alert');
      let headerAlertHeight = 0;

      if (headerAlert) headerAlertHeight = headerAlert.clientHeight;

      _this.lastScroll = _this.currentScroll;
      _this.currentScroll = window.scrollY;

      if (document.querySelector('.wb-header--open')) return false;

      // Prevent errors
      // TODO: Remove this unless errors show up
      // if (Math.abs(_this.currentScroll - _this.lastScroll) < 2) return false;

      // Scrolling down
      if (_this.currentScroll > _this.lastScroll && _this.currentScroll > 0) {
        // Fix element if we scroll past its height
        if (
          _this.currentScroll > _this.eleHeight + headerAlertHeight &&
          !_this.ele.classList.contains('wb-header--fixed')
        ) {
          _this.ele.classList.add('wb-header--fixed', 'wb-header--hidden');
          document.querySelector('body').classList.add('--sticky-menu-hidden');
          gsap.to(_this.ele, { y: -_this.eleHeight, marginTop: 0, duration: 0 });
          _this.closeAllTopLevelElements(false, 0);
        }
        // Hide element while scrolling down
        else if (
          !_this.ele.classList.contains('wb-header--hidden') &&
          _this.ele.classList.contains('wb-header--fixed')
        ) {
          _this.ele.classList.add('wb-header--hidden');
          document.querySelector('body').classList.add('--sticky-menu-hidden');
          gsap.to(_this.ele, { y: -_this.eleHeight, duration: 0.15 });
          _this.closeAllTopLevelElements(false, 0);
        }
      }
      // Scrolling up
      else {
        // Show element while scrolling up
        if (_this.ele.classList.contains('wb-header--hidden')) {
          _this.ele.classList.remove('wb-header--hidden');
          document.querySelector('body').classList.remove('--sticky-menu-hidden');
          gsap.to(_this.ele, { y: 0, duration: 0.15 });
        }

        // Release when we reach the top of the page
        if (_this.currentScroll <= 2) {
          gsap.to(_this.ele, { marginTop: headerAlertHeight, duration: 0.15 });
          _this.ele.classList.remove('wb-header--fixed');
        }
      }
    });

    document.dispatchEvent(new CustomEvent('scroll'));
    _this.setInitialScrollState();
  }

  setInitialScrollState() {
    const _this = this;
    const headerAlert = document.querySelector('#wb-alert');
    let headerAlertHeight = 0;

    if (headerAlert) headerAlertHeight = headerAlert.clientHeight;

    if (
      _this.currentScroll > _this.eleHeight + headerAlertHeight &&
      !_this.ele.classList.contains('wb-header--fixed')
    ) {
      _this.ele.classList.add('wb-header--fixed', 'wb-header--hidden');
      document.querySelector('body').classList.add('--sticky-menu-hidden');
      gsap.to(_this.ele, { marginTop: 0, duration: 0 });
    }
  }

  toggleOverlay() {
    const _this = this;
    return new Promise((resolve, reject) => {
      const headerIsOpen = document.querySelector('.wb-header--open');
      if (headerIsOpen) {
        _this.toggleMenu();
      }
    });
  }

  openSearchbar() {
    const _this = this;
    return new Promise((resolve, reject) => {
      const headerElement = document.querySelector('.wb-header');
      const searchbarElement = headerElement?.querySelector('.wb-header__searchbar');
      const navBarElement = headerElement?.querySelector('.wb-header__top');
      searchbarElement?.setAttribute('aria-hidden', false);
      searchbarElement?.querySelector('.wb-header__searchbar__input').focus();
      navBarElement?.classList.add('--is-hidden');
      headerElement?.classList.add('wb-header--search-open');
    });
  }

  closeSearchbar() {
    const _this = this;
    return new Promise((resolve, reject) => {
      const headerElement = document.querySelector('.wb-header');
      const searchbarElement = headerElement?.querySelector('.wb-header__searchbar');
      const navBarElement = headerElement?.querySelector('.wb-header__top');
      searchbarElement?.setAttribute('aria-hidden', true);
      navBarElement?.focus();
      navBarElement?.classList.remove('--is-hidden');
      headerElement?.classList.remove('wb-header--search-open');
    });
  }

  toggleMenu() {
    return new Promise((resolve, reject) => {
      const burger = document.querySelector('#wb-burger');

      if (burger.classList.contains('is-animating')) {
        reject('Target is animating.');
        return false;
      }

      const headerIsReleased = !document.querySelector('.wb-header--fixed');
      const headerIsOpen = document.querySelector('.wb-header--open');

      burger._toggleAriaExpanded();
      burger.classList.add('is-animating');
      this.ele.classList.toggle('wb-header--open');

      document.querySelectorAll('html, body').forEach(e => e.classList.add('--locked'));
      burger.classList.remove('is-animating');
      if (headerIsOpen) {
        document.querySelectorAll('html, body').forEach(e => e.classList.remove('--locked'));
      }
    });
  }

  toggleSubMenu(e) {
    const _this = this;
    return new Promise((resolve, reject) => {
      const ele = e.targetElement;
      const topMenu = ele.closest('.wb-menu__item');
      const submenu = ele.querySelector('.sub-menu');
      const isOpen = submenu?.getAttribute('aria-expanded') === 'true';
      _this.closeAllTopLevelElements(false, 0);
      if (isOpen) {
        topMenu?.setAttribute('aria-expanded', false);
        submenu?.setAttribute('aria-expanded', false);
      } else {
        topMenu?.setAttribute('aria-expanded', true);
        submenu?.setAttribute('aria-expanded', true);
      }
    });
  }

  closeSubMenu(e) {
    return new Promise((resolve, reject) => {
      const ele = e.targetElement;
      const submenu = ele.querySelector('.sub-menu');
      submenu?.setAttribute('aria-expanded', false);
    });
  }

  fixSubmenus() {
    const _this = this;

    document.querySelectorAll('.wb-menu--main .level-0')?.forEach(e => {
      const submenu = e.querySelector('.sub-menu');

      if (submenu) {
        const menuPadding = parseFloat(
          window.getComputedStyle(_this.ele.querySelector('.container-fluid')).paddingLeft
        );

        const menuWidth = _this.ele.clientWidth - parseFloat(menuPadding) * 2;
        const menuItemLeftPosition = e.offsetLeft;
        const submenuWidth = submenu.clientWidth;
        const submenuFitsInContainer =
          menuItemLeftPosition - menuPadding + submenuWidth < menuWidth;

        if (submenuFitsInContainer) submenu.classList.remove('sub-menu--right');
        else submenu.classList.add('sub-menu--right');
      }
    });
  }

  closeAllTopLevelElements(ele, duration = 0.35) {
    const parent = ele?.closest?.('.wb-menu__item') || false;
    const level = ele ? parent.getAttribute('data-level') : 0;
    const parentNav = ele?.closest?.('nav') || this.ele;

    if (level == 0) {
      parentNav.querySelectorAll('.level-0[aria-expanded="true"] > .sub-menu').forEach(e => {
        if (e.closest('.level-0') != parent) {
          e?.setAttribute('aria-expanded', 'false');
          e?.closest('.level-0')?.setAttribute('aria-expanded', 'false');
        }
      });
    }
  }

  closeMenu() {
    if (document.querySelector('.wb-header--open')) {
      document.querySelector('#wb-burger').dispatchEvent(new Event('click'));
    }

    this.closeAllTopLevelElements(false, 0);
  }

  toggleAlert() {
    const alert = document.querySelector('#wb-alert');
    const alertIsVisible =
      alert && alert.style.display !== 'none' && !document.querySelector('.wb-header--fixed');

    if (alertIsVisible) {
      const marginTop = document.querySelector('.wb-header--open') ? 0 : alert.clientHeight;
      gsap.to(this.ele, { marginTop, duration: 0.15 });
    }
  }

  setAlertOffset() {
    const alert = document.querySelector('#wb-alert');
    const alertIsVisible =
      alert && alert.style.display !== 'none' && !document.querySelector('.wb-header--fixed');

    if (alertIsVisible) this.ele.style.marginTop = `${alert.clientHeight}px`;
    else this.ele.style.marginTop = 0;
  }

  resize(force = false) {
    const _this = this;
    this.ele = document.querySelector('#masthead');

    const oldVw = this.oldVw;
    if (this.vw != this.oldVw || force) {
      this.oldVw = this.vw;
      this.vw = window.innerWidth;
      this.vh = window.innerHeight;
      this.eleHeight = this.ele.clientHeight;

      if(oldVw && oldVw !== this.vw) {
        // Only close the menu when the width of the page change, not the height.
        this.closeMenu();
      }
      this.setAlertOffset();
    }
  }
}
