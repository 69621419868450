import tippy from 'tippy.js';

export class WbTooltip {
  constructor() {
    this.init();
  }

  init() {
      for (const tooltip of document.querySelectorAll('[data-tooltip]')) {
      tippy(tooltip, {
          content: tooltip?.dataset?.tooltip || '',
          allowHTML: true,
          interactive: true,
          theme: 'webit',
      });
    }
  }
}
