export class WbAccordion {
  constructor() {
    this.init();
  }

  init() {
    document.querySelectorAll('.wb-accordion .wb-accordion__filter-btn')?.forEach((el) => {
      el.addEventListener('click', (e) => this.filterClick(e));
    });
    document.querySelectorAll('.wb-accordion .wb-accordion__trigger').forEach((el) => {
      el.addEventListener('click', (e) => this.toggleClick(e));
    });
  }

  filterClick(e) {
    const btnClick = e.target;
    const parent = e.target ?
      e.target?.closest('.wb-accordion__wrapper') :
      e.closest('.wb-accordion__wrapper');

    parent?.querySelectorAll('.wb-accordion__filter-btn[aria-selected="true"]')?.forEach(btn => {
      btn.setAttribute('aria-selected', false);
    });
    btnClick?.setAttribute('aria-selected', true);

    parent?.querySelectorAll('.wb-accordion__block').forEach(bloc => {
      const toggleName = btnClick?.getAttribute('aria-controls');
      if (toggleName && toggleName !== 'all' && bloc.id != toggleName) {
        bloc.classList.add('--hidden');
        bloc.setAttribute('aria-hidden', true);
      } else {
        bloc.classList.remove('--hidden');
        bloc.setAttribute('aria-hidden', false);
      }
    });
  }

  toggleClick(e) {
    let elem;
    const parent = e.target ?
      e.target?.closest('.wb-accordion__item') :
      e.closest('.wb-accordion__item');

    const { group } = parent?.dataset;

    if (e.target?.classList.contains('.wb-accordion__trigger')) {
      elem = e.target;
    } else {
      elem = parent?.querySelector('.wb-accordion__trigger');
    }

    const target = parent?.querySelector(`#${elem.getAttribute('aria-controls')}`);

    if (target) {
      if (group > 0 && e.type && e.type === 'click') {
        document
          .querySelectorAll(
            `.wb-accordion__item[data-group="${group}"] .wb-accordion__trigger[aria-expanded="true"]`
          )
          ?.forEach((accordionTrigger) => {
            if (accordionTrigger.id !== e?.target.id && e?.target.id) {
              this.toggleClick(accordionTrigger);
            }
          });
      }

      elem._toggleAriaExpanded();
      target._slideToggle();
    } else {
      if (elem.getAttribute('aria-controls'))
        console.error(
          `No target was found for accordion__panel with ID of ${elem.getAttribute(
            'aria-controls'
          )}`
        );
      else if (elem) {
        console.error(`No aria-controls was found on clicked accordion__trigger element`);
      } else {
        console.error(`No target accordion was found`);
      }

      return;
    }
  }
}
