import './prototypes';
import { WebitScrollTo } from './modules/WebitScrollTo';
import { PrintController } from './modules/PrintController';
import { BackToTop } from './modules/BackToTop';
import { WbAccordion } from './modules/WbAccordion';
import { WbCarousel } from './modules/WbCarousel';
import { WbScrollTriger } from './modules/WbScrollTriger';
import { WbShareLink } from './modules/WbShareLink';
import { WbInfiniteScroll } from './modules/WbInfiniteScroll';
import { WbLanguage } from './modules/WbLanguage';
import { WbPaymentForm } from './modules/WbPaymentForm';
import { WbProduct } from './modules/WbProduct';
import { WbProductFilters } from './modules/WbProductFilters';
import { WbProductModal } from './modules/WbProductModal';
import { WbSidebar } from './modules/WbSidebar';
import { WbSocialShare } from './modules/WbSocialShare';
import { WbTooltip } from './modules/WbTooltip';
import { WbUtils } from './modules/WbUtils';
import { WbWishlist } from './modules/WbWishlist';
import { SearchController } from './modules/SearchController';
import { SearchOverlay } from './modules/SearchOverlay';
import { Form } from './modules/Form';
import { Masthead } from './modules/Masthead';
import { HeaderNotification } from './modules/HeaderNotification';
import { WbDataLayers } from './modules/WbDataLayers';
import { Fancybox } from '@fancyapps/ui';
import './modules/WbSampleProduct';

document.addEventListener('readystatechange', (event) => {
  if (document.readyState == 'complete') {
    /**
     * ALL OF THESE ARE GLOBALS
     * DO NOT IMPORT IN A DIFFERENT MODULE
     */
    window.dataLayers = new WbDataLayers();
    window.WebitScrollTo = new WebitScrollTo();
    window.PrintController = new PrintController();
    window.BackToTop = new BackToTop();
    window.WbAccordion = new WbAccordion();
    window.WbCarousel = new WbCarousel();
    window.WbInfiniteScroll = new WbInfiniteScroll();
    window.WbLanguage = new WbLanguage();
    window.WbPaymentForm = new WbPaymentForm();
    window.WbProduct = new WbProduct();
    window.WbProductFilters = new WbProductFilters();
    window.WbProductModal = new WbProductModal();
    window.WbScrollTriger = new WbScrollTriger();
    window.WbShareLink = new WbShareLink();
    window.WbSidebar = new WbSidebar();
    window.WbSocialShare = new WbSocialShare();
    window.WbTooltip = new WbTooltip();
    window.WbUtils = new WbUtils();
    window.WbWishlist = new WbWishlist();
    window.SearchController = new SearchController();
    window.SearchOverlay = new SearchOverlay();
    window.HeaderNotification = new HeaderNotification();
    window.Form = new Form();
    window.Masthead = new Masthead();
  }
});
