import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

export class WbScrollTriger {
  constructor() {
    gsap.registerPlugin(ScrollTrigger);
    this.init();
  }

  init() {
    for (const video of document.querySelectorAll(".video-player")) {
      ScrollTrigger.create({
         trigger: video,
         onEnter: () => video.play(),
         onEnterBack: () => video.play(),
         onLeave: () => video.pause(),
         onLeaveBack: () => video.pause(),
      });
   }
  }
}
