import { TrapFocus } from './TrapFocus';

export class SearchOverlay {
  constructor() {
    this.init();
  }

  init() {
    this.setProperties();
    this.bindListeners();
    this.trapFocus = new TrapFocus({ ele: this.overlay, activeClass: 'overlay--open' });
  }

  setProperties() {
    this.overlay = document.querySelector('#search__form');
    this.searchInput = document.querySelector('#search');
    this.searchTriggers = document.querySelectorAll('.search__form__trigger');
  }

  bindListeners() {
    const _this = this;

    document
      .querySelector('body')
      ._addCustomEventListener('click', '.search__form__trigger', _this.openSearch.bind(_this));

    document
      .querySelector('body')
      ._addCustomEventListener('click', '.search__form__close', _this.closeSearch.bind(_this));

    document.addEventListener('keydown', _this.keydownEventListener.bind(_this));
  }

  openSearch(e) {
    this.refTrigger = e.targetElement;
    this.searchTriggers?.forEach((trigger) => {
      trigger.setAttribute('aria-expanded', 'true');
    });
    this.overlay?._show(0.5);
    this.overlay?.classList.add('overlay--open');
    this.searchInput?.select();
    document.querySelector('html, body').classList.add('overlay--active');
  }

  closeSearch() {
    this.searchTriggers?.forEach((trigger) => {
      trigger.setAttribute('aria-expanded', 'false');
    });
    this.overlay?._hide(0.5).then(() => this.overlay?.classList.remove('overlay--open'));
    this.refTrigger.focus();
    this.refTrigger = false;
    document.querySelector('html, body').classList.remove('overlay--active');
  }

  keydownEventListener(e) {
    const _this = this;
    let isOpen = this.overlay?.classList.contains('overlay--open');

    if (!isOpen) return;

    switch (e.key) {
      case 'Escape':
        _this.closeSearch();
        break;
    }
  }
}
