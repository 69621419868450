import barba from '@barba/core';

export class SearchController {
  constructor() {
    this.ignoredParams = ['action', 'lang'];
    this.init();
  }

  init() {
    const _this = this;

    return new Promise((resolve) => {
      this.bindListeners();

      resolve();
    });
  }

  bindListeners() {
    const _this = this;

    document
      .querySelector('body')
      ._addCustomEventListener('submit', '#globalSearch', _this.submitGlobalSearch.bind(_this));

    document
      .querySelector('body')
      ._addCustomEventListener('change', '.filter__select--force', _this.selectFilter.bind(_this));

    document
      .querySelector('body')
      ._addCustomEventListener('click', '.load-more', _this.loadMoreResults.bind(_this));

    document
      .querySelector('body')
      ._addCustomEventListener('click', '.js-submit-search-form', _this.submitForm.bind(_this));

    document
      .querySelector('body')
      ._addCustomEventListener('submit', '.search-box__form', _this.submitForm.bind(_this));
  }

  loadMoreResults(e) {
    e.preventDefault();

    const form = document.querySelector('.search-box__form');
    const el = e.targetElement;
    const page = el.getAttribute('data-pg');

    this.page = parseInt(page) + 1;
    this.setUrlParam('pg', this.page);
    this.setUrlParams(form);
    this.prepareAjax(form, true);
    this.doAjax();
  }

  selectFilter(e) {
    this.submitForm(e);
  }

  submitForm(e, prevent = true) {
    if (prevent) e.preventDefault();

    if (this.isGlobalSearchPage()) {
      this.submitGlobalSearch(e, false);
      return false;
    }

    const form = e.target.closest('.search-box__form');

    this.setUrlParams(form);
    this.prepareAjax(form);
    this.doAjax();
  }

  submitGlobalSearch(e, overlay = true) {
    e.preventDefault();

    const form = overlay ? e.target : document.querySelector('.search-box__form');
    const keywords = form.querySelector('[name="s"]').value;
    const url = form.getAttribute('action') + '?s=' + keywords;

    document.querySelectorAll('[name="s"]').forEach((el) => (el.value = keywords));

    if (overlay) SearchOverlay.closeSearch();

    if (!this.isGlobalSearchPage()) {
      barba.go(url);
      return false;
    }

    this.setUrlParams(form);
    this.prepareAjax(form);
    this.doAjax();
  }

  setUrlParam(name, val) {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    params.set(name, val);
    this.setHistoryState(url);
  }

  delUrlParam(name) {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    params.delete(name);
    this.setHistoryState(url);
  }

  setUrlParams(form) {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    let formData = new FormData(form);

    for (const d of formData.entries()) {
      if (this.ignoredParams.includes(d[0])) continue;
      params.set(d[0], d[1]);
      if (d[1] == '' && d[0] != 's') params.delete(d[0]);
    }

    this.setHistoryState(url);
  }

  getUrlParams(form) {
    let data = '';
    const formData = new FormData(form);
    formData.append('lang', ajax_obj.lang);

    data += '&' + new URLSearchParams(formData);

    if (this.page) data += '&pg=' + this.page;

    return data;
  }

  setHistoryState(url) {
    history.replaceState(null, '', url.href);
  }

  isGlobalSearchPage() {
    const url = new URL(window.location.href);
    const reg = new RegExp(url.origin + '\\/\\?s?.*');

    return url.href.match(reg);
  }

  prepareAjax(form, paginate = false) {
    this.container = document.querySelector('.filters__section');

    if (!paginate) {
      this.resetPagination();
      this.filter = true;
    }

    this.data = this.getUrlParams(form);
  }

  toggleAjaxOverlay() {
    const overlayIsActive = document.querySelector('html').classList.contains('load-overlay');

    if (!overlayIsActive) {
      const icon = document.createElement('div');
      icon.classList.add('load-overlay__icon');

      document.querySelector('html').classList.add('load-overlay');
      document.querySelector('body').appendChild(icon);
    } else {
      document.querySelector('.load-overlay').classList.remove('load-overlay');
      document.querySelector('.load-overlay__icon').remove();
    }
  }

  doAjax() {
    const _this = this;

    this.toggleAjaxOverlay();

    fetch(ajax_obj.ajaxurl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
      },
      body: _this.data,
    })
      .then((response) => {
        if (!response.ok) {
          this.toggleAjaxOverlay();
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.text())
      .then((data) => {
        const PARSER = new DOMParser();
        const DOC = PARSER.parseFromString(data, 'text/html');

        const blocks = DOC.querySelectorAll('.filters__block');
        const loadMore = DOC.querySelector('.results__more');
        const count = DOC.querySelector('.results__count');
        const pagination = DOC.querySelector('.search-pagination');

        // Blocks
        if (_this.filter) _this.container.querySelectorAll('.filters__block')._remove();
        if (blocks.length)
          _this.container.querySelector('.filters__section__results')._appendAll(blocks);

        // Load more
        const currentLoadMore = _this.container.querySelector('.results__more');
        if (currentLoadMore) currentLoadMore.replaceWith(loadMore);

        // Count
        const currentCount = _this.container.querySelector('.results__count');
        if (currentCount) currentCount.replaceWith(count);

        // Pagination
        const currentPagination = _this.container.querySelector('.search-pagination');
        if (currentPagination) currentPagination.replaceWith(pagination);

        WebitScrollTo.scrollTo({ element: '.filters__section' });

        _this.resetProps();
        _this.toggleAjaxOverlay();
      })
      .catch((error) => console.error(error));
  }

  resetProps() {
    this.filter = false;
    this.container = false;
  }

  resetPagination() {
    this.page = false;
    this.delUrlParam('pg');
  }
}
