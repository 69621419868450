export function getCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function setCookie(name, value, minutes) {
  var d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
}

export function removeCookie(name) {
  document.cookie = name + '=; Max-Age=0';
}