/**
 * To make it work you'll need to:
 * - Use with the twig embed module at `_parts/embed/sidebar`
 * - Add an id to the embed
 * - Add class `wb-sidebar__open-btn` and the custom data-attribute`data-sidebar-target="#ID"` to the trigger button
 */

export class WbSidebar {
  constructor() {
    this.init();
  }

  init() {
    this.toggleSidebar();
    this.checkLoginError();
  }

  toggleSidebar() {
    for (const sidebarBtn of document.querySelectorAll('.wb-sidebar__open-btn')) {
      sidebarBtn?.addEventListener('click', (e) => this.openSidebar(e));
    }
    for (const sidebar of document.querySelectorAll('.wb-sidebar')) {
      document?.querySelector('.wb-overlay')?.addEventListener('click', () => this.closeSidebar());
      sidebar?.querySelector('.wb-sidebar__close-menu')?.addEventListener('click', () => this.closeSidebar());
    }
  }

  openSidebar(e) {
    const { sidebarTarget } = e.currentTarget?.dataset;
    let targetElement = null;
    if (sidebarTarget && document.querySelector(sidebarTarget)) {
      targetElement = document.querySelector(sidebarTarget);
    } else if (e.target?.parentElement?.querySelector('.wb-sidebar')) {
      console.warn(
        'please use a `data-sidebar-target` on your button and add an `id` to the sidebar embed'
      );
      targetElement = e.target?.parentElement?.querySelector('.wb-sidebar');
    } else {
      console.error(
        'No sidebar found near the button, please use a `data-sidebar-target` on your button and add an `id` to the sidebar embed'
      );
      return;
    }
    document.querySelectorAll('html, body').forEach((e) => e.classList.add('--locked'));
    targetElement?.classList.add('wb-sidebar--open');
  }

  closeSidebar() {
    document.querySelectorAll('html, body').forEach((e) => e.classList.remove('--locked'));
    document.querySelector('.wb-sidebar.wb-sidebar--open')?.classList.remove('wb-sidebar--open');
  }

  checkLoginError() {
    // This function will re-open the login-sidebar in case of login error.
    const form = document.querySelector('.wb-login-sidebar');
    const error = form?.querySelector('.error-message');
    if (error) {
      const e = {
        currentTarget: {
          dataset: {
            sidebarTarget: '#account'
          }
        }
      };
      this.openSidebar(e);
    }
  }


}
