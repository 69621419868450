import Swiper from 'swiper/swiper-bundle';

export class WbCarousel {
  constructor() {
    this.init();
  }

  init() {
    for (const carousel of document.querySelectorAll('.swiper')) {
      const {
        hasNavigation,
        isAutoplay,
        lgBreakpoint,
        lgSpaceBetween,
        mdBreakpoint,
        mdSpaceBetween,
        smBreakpoint,
        smSpaceBetween,
        speed,
      } = carousel.dataset;

      const carouselParams = {
        speed,
        spaceBetween: parseInt(smSpaceBetween, 10),
        slidesPerView: parseInt(smBreakpoint, 10),
        breakpoints: {
          768: {
            spaceBetween: parseInt(mdSpaceBetween, 10),
            slidesPerView: parseInt(mdBreakpoint, 10),
          },
          1280: {
            spaceBetween: parseInt(lgSpaceBetween, 10),
            slidesPerView: parseInt(lgBreakpoint, 10)
          }
        }
      };
      if (isAutoplay) {
        Object.assign(carouselParams, {
          autoplay: {
            delay: 5000,
          },
        });
      }
      if (hasNavigation && carousel.querySelector('.swiper-navigation')) {
        Object.assign(carouselParams, {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }
      if (carousel.querySelector('.swiper-pagination')) {
        Object.assign(carouselParams, {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
        });
      }

      new Swiper(carousel, carouselParams);
    }
  }
}
