export class WbShareLink {
  constructor() {
    this.init();
  }

  init() {
    this.openShareOverlay();
  }

  /**
   * Open the navigator.share on mobile device if available,
   * if not, open a empty mailto with a body with the current url instead.
   * Required: class '.share-link' on a link element ( <a> ).
   */
  openShareOverlay() {
    const shareLink = document.querySelectorAll('.share-link');
    shareLink?.forEach((link) => {
      link?.addEventListener('click', (e) => {
        e.preventDefault();
        const url = link.getAttribute('href') || '#';

        if (navigator.share) {
          const title = link.getAttribute('title') || document.title;

          navigator.share({
            title,
            url,
          });
        } else {
          // The mailto required a encodedURL.
          const encodedUrl = encodeURIComponent(url);
          const mailToLink = `mailto:?body=${encodedUrl}`;

          location.href = mailToLink;
        }
      });
    });
  }
}
