export class WbWishlist {
  constructor() {
    this.init();
  }

  init() {
    this.renameToggle();
    this.newProjectToggle();
  }

  renameToggle() {
    const renameBtnList = document.querySelectorAll('.wb-wishlist-detail__rename-btn');
    renameBtnList?.forEach(renameBtn => {
      renameBtn?.addEventListener('click', () => {
        const parentElement = renameBtn.closest('.wb-wishlist-detail__rename');

        parentElement?.querySelector('.wb-wishlist-detail__rename-form')?._slideToggle();
      });
    });
  }

  newProjectToggle() {
    const newProjectBtn = document.querySelectorAll('.wb-wishlist-listing__btn-new');
    newProjectBtn?.forEach(newBtn => {
      newBtn?.addEventListener('click', () => {
        newBtn.nextElementSibling?._slideToggle();
      });
    });
  }
}
