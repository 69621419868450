import { getCookie, setCookie } from './Cookies';
import { gsap } from 'gsap';

export class HeaderNotification {
  constructor() {
    this.init();
  }

  init() {
    this.bindListeners();
  }

  bindListeners() {
    const _this = this;

    const links = document.querySelectorAll('.alert__close');
    links.forEach((link) => _this.addAlertListener(link));
  }

  addAlertListener(link) {
    const _this = this;
    const data = link.dataset.dismiss;

    link.addEventListener('click', _this.closeAlert.bind(_this));

    const cookie = getCookie(data + 'Close');
    if (cookie && cookie != 0) {
      const alert = link.closest('[role="' + data + '"]');
      alert.classList.add('d-none');
    }
  }

  closeAlert(e) {
    const target = e.currentTarget;
    const data = target.dataset.dismiss;
    const alert = target.closest('[role="' + data + '"]');

    alert.classList.add('alert--close');
    setCookie(data + 'Close', 1, 60 * 24);
    setTimeout(() => {
      alert.classList.add('d-none');
      gsap.to(Masthead.ele, { marginTop: 0, duration: 0.15 });
    }, 200);
  }
}
