export class WbInfiniteScroll {
  constructor() {
    this.init();
  }

  init() {
    this.triggerLoadingAction();
  }

  triggerLoadingAction() {
    const paginationLoadMore = document.querySelectorAll('.pagination__load-more');
    paginationLoadMore?.forEach(loadMoreBtn => {

      loadMoreBtn?.addEventListener('click', (e) => {
        e.preventDefault();
        const parentList = loadMoreBtn.closest('.infinite-scrolling-list');
        parentList?.querySelector('.infinite-scrolling-list__loading-list')?.classList.remove('d-none');
        loadMoreBtn.classList.add('btn--disabled');
        this.loadMoreProjects(parentList);
      });
    });
  }

  loadMoreProjects(parentList) {
    const { nextUrl, type } = (parentList || document).querySelector(
      '.infinite-scrolling-list__list'
    )?.dataset;
    if (nextUrl) {
      const fetching = fetch(nextUrl);
      fetching
        .then((response) => response.text())
        .then((htmlResponse) => this.replaceElement(htmlResponse, type))
        .catch((error) => console.error('Error:', error));
    }
  }

  replaceElement(htmlResponse, currentType) {
    if (htmlResponse) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlResponse, 'text/html');
      const bodyElement = doc?.childNodes[0];
      if (bodyElement) {
        const childNodes = bodyElement.nextSibling.querySelectorAll('.infinite-scrolling-list__list');
        childNodes.forEach((childNode, key) => {
          const { nextUrl, currentPage, type } = childNode.dataset;
          let urlToLoad = nextUrl; 
          // Don't refresh data if its not the good content
          if ( currentType && type !== currentType) {
            console.log('current type is not the same', currentType, type);
            return;
          }
          if (type && nextUrl) {
            console.log('type and url', type, nextUrl, childNode);
            const url = new URL(nextUrl);
            url.searchParams.set('type', type);
            urlToLoad = url.href;
          }
          const currentListElement = type ? document.querySelector(`.infinite-scrolling-list[data-type="${type}"]`) : document.querySelectorAll('.infinite-scrolling-list')[key];
          const currentProjectsListElement = currentListElement?.querySelector('.infinite-scrolling-list__list');
          currentListElement?.querySelector('.infinite-scrolling-list__loading-list')?.classList.add('d-none');
          currentProjectsListElement.append(...childNode.childNodes);
          currentProjectsListElement.setAttribute('data-next-url', urlToLoad);
          currentListElement?.querySelector('.pagination__load-more')?.classList.remove('btn--disabled');
          currentListElement?.querySelector('.pagination__load-more')?.setAttribute('href', urlToLoad);
          if (currentPage && currentListElement?.querySelector('.pagination__context > span')) {
            currentListElement.querySelector('.pagination__context > span').innerText = currentPage;
          }
          if (!nextUrl) {
            currentListElement?.querySelector('.pagination__load-more')?.classList.add('d-none');
          }
          const event = new Event('rebuild-form');
          document.dispatchEvent(event);
        });
      }
    }
  }
}
