export class PrintController {
  constructor() {
    this.init();
  }

  init(){
    return new Promise(resolve => {
      this.bindListeners();
      resolve();
    });
  }

  bindListeners () {
    const _this = this;

    document.querySelector('body')._addCustomEventListener('click', '.js-print', _this.openPrint.bind(_this));
  }

  openPrint () {
    window.print();
  }
}
