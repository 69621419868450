export class WbDataLayers {
  constructor() {
    this.events = {};
     this.init();
  }

  init() {
    this.events = [
 
      {
        event: 'purchase',
        routes: ['/achat/confirmation', '/en-ca/checkout/confirm',  '/en-us/checkout/confirm'],
        handler: this.completeCheckout.bind(this),
      },
   
    ];

    this.bindListeners();
  }

  bindListeners() {
     this.events.forEach((event) => {
      if (event.routes.some((route) => new RegExp(route).test(window.location.pathname))) {
        event.handler();
      }
    });
  }


  async completeCheckout() {
    const params = new URLSearchParams(window.location.href);
    const isPurchase = Boolean(params.get('success'));
    if (isPurchase) {
      const order = JSON.parse(document.querySelector('[data-purchase]')?.dataset.purchase);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.transaction_id,
          affiliation: order.affiliation,
          total: order.price,
          subtotal: order.subtotal,
          discount: order.discount,
          currency: order.currency,
          tax: order.tax,
          shipping: order.shipping,
          items: order.items.map((item) => {
            return {
              item_name: item.title,
              item_id: item.sku,
              price: item.price,
              quantity: item.qty,
            };
          }),
        },
      });

      setTimeout(function() {
      window.location.href = window.location.href.replace('&success=true', '');
}, 1000);
    }
  }
}