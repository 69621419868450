export class TrapFocus {
  constructor(options = {}) {
    const defaultOptions = {
      focusableElements: 'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])',
      ele: '',
      activeClass: '',
      close: null
    };

    this.options = { ...defaultOptions, ...options };
    this.init();
  }

  init() {
    this.bindListeners();
  }

  bindListeners() {
    const _this = this;
    document.addEventListener('keydown', _this.keydownEventListener.bind(_this));
  }

  keydownEventListener(e) {
    const isActive = this.options.ele && this.options?.ele.classList?.contains(this.options?.activeClass);

    if (!isActive)
      return;

    switch (e.key) {
      case 'Tab':
        this.trapFocus(e);
        break;
      case 'Escape':
        this.escapeTrap();
        break;
    }
  }

  trapFocus(e) {
    const _this = this;
    const focusableContent = this.options?.ele.querySelectorAll(_this.options?.focusableElements);
    const firstFocusableElement = this.getVisibleFocusableElement(focusableContent);
    const lastFocusableElement = this.getVisibleFocusableElement(focusableContent, true);

    if (e.shiftKey)
      this.loopFocus(e, firstFocusableElement, lastFocusableElement);
    else
      this.loopFocus(e, lastFocusableElement, firstFocusableElement);
  }

  escapeTrap(){
    const _this = this;
    const elements = document.querySelectorAll(`.${this.options.activeClass}, [aria-expanded="true"]`);

    elements.forEach(el => {
      el.classList.remove(`${_this.options.activeClass}`);

      if(el.getAttribute('aria-expanded'))
        el.setAttribute('aria-expanded', 'false');
    });
  }

  loopFocus(e, target, goto){
    if (document.activeElement === target ) {
      e.preventDefault();

      if(this.options.close)
        this.options.close.focus();
      else
        goto.focus();
    }else if(document.activeElement === this.options.close){
      e.preventDefault();
      goto.focus();
    }
  }

  getVisibleFocusableElement(focusableContent, reverse = false){
    if(reverse) {
      let element;

      for(let i = 1; i < focusableContent.length; i++) {
        element = focusableContent[focusableContent.length - i];
        if(typeof element !== 'undefined' && window.getComputedStyle(element, null).display != 'none')
          return element;
      }
    }else{
      for(let focusableElement of focusableContent) {
        if(typeof focusableElement !== 'undefined' && window.getComputedStyle(focusableElement, null).display != 'none')
          return focusableElement;
      }
    }
  }
}
