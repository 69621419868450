import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';

/**
 * @class WebitScrollTo
 * @desc Controls "scroll to" methods
 * @version 1.2
 */
export class WebitScrollTo {
  constructor() {
    gsap.registerPlugin(ScrollToPlugin);
    this.scrollToAnchor();
    this.init();
  }

  init() {
    return new Promise((resolve, reject) => {
      this.bindListeners();
      resolve();
    });
  }

  bindListeners() {
    const _this = this;

    document
      .querySelectorAll('a[href^="#"]')
      .forEach((link) => link.addEventListener('click', _this.scrollToAnchor.bind(_this)));
  }

  /**
   * scrollTo
   * @param {Object} options
   * @param {selector} options.element Destination element
   * @param {number} options.duration Duration of the animation
   * @param {number} options.offset Offset of the animation (https://greensock.com/docs/v3/Plugins/ScrollToPlugin)
   * @param {number} options.delay Delay before the animation
   * @param {string} options.ease Easing of the animation (https://greensock.com/docs/v3/Eases)
   */
  scrollTo(options = {}) {
    let defaultOptions = {
      duration: 0.5,
      offset: 0,
      delay: 0,
      ease: 'linear',
    };

    const OPTIONS = { ...defaultOptions, ...options };

    if ('element' in OPTIONS) {
      return new Promise((resolve, reject) => {
        if (!document.querySelector(OPTIONS.element)) {
          reject(new Error(`${OPTIONS.element} could not be found.`));
          return;
        }

        gsap.to(window, {
          scrollTo: { y: OPTIONS.element, offsetY: OPTIONS.offset },
          duration: OPTIONS.duration,
          delay: OPTIONS.delay,
          ease: OPTIONS.easing,
          onComplete: resolve,
        });
      });
    }
  }

  scrollToAnchor(e) {
    return new Promise((resolve, reject) => {
      if (e) {
        e.preventDefault();
        let target = e.currentTarget.getAttribute('href');
        history.pushState(null, null, target);
      }

      if (location.hash) this.scrollTo({ element: location.hash }).then(resolve);
      else resolve();
    });
  }
}
