// noinspection JSUnresolvedReference

import Alpine from 'alpinejs';

document.addEventListener('alpine:init', function() {
  Alpine.data('variants', (variants = {}) => ({
    collection: '',
    variants: variants,
    filters: {
      wood: ['woodFinish', 'design', 'finish'],
      lacquer: ['hdfFinish', 'hdfColor'],
      polymer: ['polymerFinish', 'color'],
      laminate: ['laminateColor', 'laminateDesign'],
    },
    productPage : undefined,
    isValid: false,
    init() {
      this.collection = this.$refs.collections.value;
      this.productPage = this.$refs.form.closest('.wb-product-details__inner');
      this.update();
      this._preload();
    },
    changeCollection() {
      this.collection = this.$el.value;
      this.update();
    },
    toggleFilter(name) {
      return this.filters[this.collection].includes(name);
    },
    update() {
      const attributes = this.filters[this.collection];
      const filters = {
        collection: this.collection
      };

      attributes.map((item) => {
        const element = document.getElementById(item);

        if(element) {
          filters[item] = element.value;
        }
      });

      const variant = this._matchVariant(filters);

      if(variant) {
        // set variant image
        const image = variant.variantImage || variant.productImage;
        image && document.querySelector('.wb-product-details__image')?.setAttribute('src', image);
        // set purchasable id
        this.$refs.form.querySelector('input[name="purchasableId"]').value = variant.id;
        this.productPage.querySelectorAll('[name="elementId"]')?.forEach((item) => {
          item.setAttribute('value', variant.id);
        });
        this.productPage.querySelector('.wb-product-heading__price').innerHTML = variant.price;
        this.$refs.submit.removeAttribute('disabled');
        this.isValid = true;
      } else {
        this.$refs.submit.setAttribute('disabled', true);
        this.isValid = false;
      }
    },
    _matchVariant(filters) {
      const items = this.variants.filter((variant) => {
        for(let filter in filters) {
          if(variant[filter] !== filters[filter]) {
            return false;
          }

          if(! variant.stock) {
            return false;
          }
        }

        return true;
      });

      return items[0] ?? null;
    },
    _preload() {
      this.variants.map((variant) => {
        const image = variant.variantImage || variant.productImage;
        if(image) {
          const tag = new Image();
          tag.src = image;
          tag.loading = "eager";
        }
      })
    }
  }));
});

Alpine.start();