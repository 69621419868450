export class WbSocialShare {
  constructor() {
    this.init();
  }

  init() {
    const _this = this;

    return new Promise((resolve) => {
      _this.bindListeners();
      resolve();
    });
  }
  bindListeners() {
    const _this = this;

    document
      .querySelectorAll('.socials-sharing__copy-link')
      ?.forEach((btn) => (btn.onclick = _this.copyLink.bind(_this)));
  }

  copyLink(e) {
    const temp = document.createElement('input');
    document.querySelector('body').appendChild(temp);

    temp.value = window.location.href;
    temp.select();
    document.execCommand('copy');
    temp.remove();
  }
}
