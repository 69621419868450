export class Form {
  constructor() {
    this.init();
  }

  init() {
    const _this = this;
    this.overrideAjaxMessages();
    this.bindFormElement();
    document.addEventListener('rebuild-form', e => {
      this.bindFormElement();
    }, false);
  }

  bindFormElement() {
    this.initInputNumber();
    this.initInputNote();
    this.initInputCustomColor();
    this.initTrashCan();
    this.initMultipleAddCart();
    this.initRadioImage();
    this.initNextStepSubmit();
    this.initSpinner();
    this.initAddWishlist();
    this.initOnCountryChange();
    this.initEyeIcon();
  }

  overrideAjaxMessages() {
    document.querySelectorAll('.freeform-form')?.forEach(form =>
      form.addEventListener('freeform-ready', function (event) {
        // Set AJAX validation to true by default
        event.options.ajax = true;

        if (document.querySelector('html[lang*="fr"]')) {
          event.options.errorBannerMessage =
            "Désolé, une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer.";
          event.options.successBannerMessage = 'Le formulaire a été soumis avec succès!';
        }
      })
    );
  }

  initInputNumber() {
    const _this = this;
    document.querySelectorAll('.wb-input-number')?.forEach(input => {
      input?.querySelector('.wb-input-number__btn-plus')?.addEventListener('click', _this.setIncrementValue.bind(_this));
      input?.querySelector('.wb-input-number__btn-minus')?.addEventListener('click', _this.setIncrementValue.bind(_this));
    });
  }

  initInputNote() {
    const _this = this;
    document.querySelectorAll('.note-products')?.forEach(input => {
    input?.querySelector('.note-projet-product')?.addEventListener('keyup', _this.sendNote.bind(_this));
    });
  }

  sendNote(e) {
    const _this = this;
    _this.reloadForm(e?.target?.closest('form'));
  }

  initInputCustomColor() {
    const _this = this;
    document.querySelectorAll('.input_HDF_color')?.forEach(input => {
      input?.addEventListener('change', _this.setHDFValue.bind(_this));
    });

    document
      .querySelectorAll(
        `.wb-product-variant-form__select[name="options[insertionEssence]"],
        .wb-product-variant-form__select[name="options[insertionHDFColor]"],
        .wb-product-variant-form__select[name="options[insertionFinition]"],
        .js-custom-insertion-color[name="options[insertionCustomHDF]"]`
      )
      ?.forEach((input) => {
        input?.addEventListener('change', _this.setOptionsValue.bind(_this));
      });
  }

  initTrashCan() {
    const _this = this;
    document.querySelectorAll('.wb-cart-trash')?.forEach(btn => {
      btn?.querySelector('.wb-cart-trash__btn-trash')?.addEventListener('click', e => {
        btn.querySelector('.wb-cart-trash__input')?.setAttribute('checked', true);
        const parentForm = btn.closest('form');
        if (parentForm?.querySelector('input[name="redirect"]')) {
          parentForm.querySelector('input[name="redirect"]').remove();
          parentForm.submit();
        }
      });
    });
  }

  initMultipleAddCart() {
    const _this = this;
    const sidebarElement = document.querySelector('.wb-wishlist-detail__sidebar');
    const sidebarForm = sidebarElement?.querySelectorAll('form');

    if (sidebarForm && sidebarForm.length > 1) {
      const addToCartBtn = sidebarElement.querySelector('.wb-price-sidebar__submit');
      addToCartBtn?.removeAttribute('type');
      addToCartBtn?.removeAttribute('form');
      addToCartBtn?.addEventListener('click', () => {
        sidebarForm.forEach((form, index) => {
          const formData = new FormData(form);

          if (!!formData) {
            const fetchUrl = window.location;
            const fetching = fetch(fetchUrl, {
              method: "POST",
              body: formData
            });
            fetching
              .then((response) => response.text())
              .then((htmlResponse) => {
                if (index === sidebarForm.length - 1) {
                  window.location.reload();
                }
              })
              .catch((error) => console.error('Error:', error));
          }
        });
      });
    }
  }

  initAddWishlist() {
    const _this = this;
    document.querySelectorAll('.wb-product-wishlist__submit')?.forEach(btn => {
      btn?.addEventListener('click', e => {
        const confirmMsg = document.querySelector('.wb-product-wishlist__confirm-added');
        _this.reloadForm(btn?.closest('form'));
      });
    });

  }

  initRadioImage() {
    const _this = this;
    const radioList = document.querySelectorAll('.wb-input-radio-image__input');
    radioList?.forEach(radio => {
      radio.addEventListener('change', evt => {
        if (evt.currentTarget.checked) {
          const inputName = evt?.currentTarget?.getAttribute('name');
          document.querySelectorAll(`input[name="${inputName}"]`)?.forEach(input => {
            input?.closest('.wb-input-radio-image')?.classList.add('--other-selected');
          });
          evt?.currentTarget?.closest('.wb-input-radio-image')?.classList.remove('--other-selected');
        }

        const parentForm = evt?.currentTarget?.closest('form:valid');
        if (!!parentForm) {
          const submitBtn = parentForm.querySelector('.btn--disabled[type="submit"]');
          submitBtn?.classList.remove('btn--disabled');
          submitBtn?.removeAttribute('disabled');
        }
      });
    });
  }

  initNextStepSubmit() {
    const _this = this;
    document.querySelectorAll('.wb-price-sidebar__submit')?.forEach(btn => {
      const stripe = document.querySelector('.stripe-payment-elements-submit-button');

      if(stripe) {
        stripe.classList.add("btn");
        stripe.classList.add("btn--secondary");
      } else {

        const formParent = btn.closest('.wb-base-checkout');
        formParent?.querySelector('form:not(.wb-cart-products-form__empty-cart)')?.setAttribute('id', 'checkoutForm');
      }
    });

    const submit = document.querySelector('[data-payment]');

    if(submit) {
      const conditions = document.getElementById('conditions');
      conditions.addEventListener('change', function(e) {
        if(! e.currentTarget.checked) {
          submit.setAttribute('disabled', 'true');
        } else {
          submit.removeAttribute('disabled');
        }
      });
      document.getElementById('paymentForm').addEventListener('submit', function(e) {
        if(! conditions.checked) {
          e.preventDefault();
          return false;
        }
      })
    }
  }

  setIncrementValue(e) {
    const _this = this;
    const input = e?.target.closest('.wb-input-number')?.querySelector('.wb-input-number__input');
    const countField = document.querySelector('.wb-wishlist-detail__count');
    const countField2 = document.querySelector('.wb-price-sidebar__value');

    const currentValue = parseInt(input?.value, 10);
    let nbArticles = parseInt(countField?.innerText || 0, 10);
    if (currentValue > 1 && e?.target.classList?.contains('wb-input-number__btn-minus')) {
      input.value = currentValue - 1;
      --nbArticles;
    } else if (currentValue >= 1 && e?.target.classList?.contains('wb-input-number__btn-plus')) {
      input.value = currentValue + 1;
      ++nbArticles;
    }
    if (countField) {
      countField.innerText = nbArticles;
      countField2.innerText = nbArticles;
    }
    const parentObject = e?.target.closest('.wb-product-details__inner');
    const wishListList = parentObject?.querySelectorAll('.wb-product-wishlist__form');
    wishListList?.forEach((item) => {
      const inputQty = item.querySelector('[name="fields[quantityWished]"]');

      inputQty.setAttribute('value', input.value);
    });

    let totalPrice= 0;

    const currency= document.querySelector('.currency')?.value || 'cad';
    const formatter = new Intl.NumberFormat(document.documentElement.lang, {
      style: 'currency',
      currency: currency,
    });
    const productListList= document.querySelectorAll('.wb-wishlist-product');
    productListList?.forEach((item) => {
      const inputQty = parseInt(item.querySelector('.wb-input-number__input').value);
      const price = parseFloat(item.querySelector('.single-price').value);
      const totalItemPrice= (inputQty * price);
      item.querySelector('.wb-wishlist-product__total-price').innerHTML="x " + inputQty + " = " + formatter.format(totalItemPrice);
      totalPrice = totalPrice + totalItemPrice ;
    });

    document.querySelectorAll('.wb-price-sidebar__value')[1].innerHTML=formatter.format(totalPrice);
    _this.reloadForm(e?.target?.closest('form'));
  }

  setOptionsValue(e) {
    const _this = this;
    const input = e?.target;
    const optionName = input?.getAttribute('name');
    const parentObject = e?.target.closest('.wb-product-details__inner');
    const wishListList = parentObject?.querySelectorAll('.wb-product-wishlist__form');

    wishListList?.forEach((item) => {
      if (optionName === 'options[insertionEssence]') {
        if (input.value === 'hdf') {
          const finitionElement = item.querySelector(`[name="options[insertionFinition]"]`);
          finitionElement.setAttribute('value', '');
        } else {
          const hdfColorElement = item.querySelector(`[name="options[insertionHDFColor]"]`);
          const hdfCustomElement = item.querySelector(`[name="options[insertionCustomHDF]"]`);
          hdfColorElement.setAttribute('value', '');
          hdfCustomElement.setAttribute('value', '');
        }
      } else if (optionName === 'options[insertionHDFColor]' && input.value !== 'custom') {
        const hdfCustomElement = item.querySelector(`[name="options[insertionCustomHDF]"]`);
        hdfCustomElement.setAttribute('value', '');
      }

      const inputElement = item.querySelector(`[name="${optionName}"]`);
      inputElement.setAttribute('value', input.value);
    });
    _this.reloadForm(e?.target?.closest('form'));
  }

  setHDFValue(e) {
    const _this = this;
    const input = e?.target;
    const parentObject = e?.target.closest('.wb-product-details__inner');
    const wishListList = parentObject?.querySelectorAll('.wb-product-wishlist__form');
    wishListList?.forEach((item) => {
      const inputColor = item.querySelector('[name="options[Custom-Color-HDF]"]');
      inputColor.setAttribute('value', input.value);
    });
    _this.reloadForm(e?.target?.closest('form'));
  }

  reloadForm(form) {
    if (!form || form.classList.contains('wb-product-variant-form__form')) {
      return false;
    }
    const redirectElement = form.querySelector('[name="redirect"]')?.cloneNode(true);
    form.querySelector('[name="redirect"]')?.remove();
    const formData = new FormData(form);
    const formClassName = form?.classList;
    if (!!formData) {
      const fetchUrl = window.location;
      const fetching = fetch(fetchUrl, {
        method: "POST",
        body: formData
      });

      fetching
        .then((response) => response.text())
        .then((response) => {
          if (redirectElement) {
            form?.appendChild(redirectElement);
          }

          document.querySelector('.wb-spinner__container')?.classList?.add('d-none');
          document.querySelector('.wb-product-wishlist__confirm-added')?.classList.remove('d-none');

          setTimeout(() => {
            document.querySelector('.wb-product-wishlist__confirm-added')?.classList.add('d-none');
          }, 2500);

          this.replaceForm(response, formClassName, form);
        })
        .catch((error) => console.error('Error:', error));
    }
  }

  initOnCountryChange() {
    const _this = this;
    const addressFields = document.querySelectorAll('[name="shippingAddress[administrativeArea]"], [name="shippingAddress[countryCode]"]');
    addressFields.forEach(field => {
      field?.addEventListener('change', (ev) => {
        if (document.querySelector('[name="shippingAddress[administrativeArea]"]')?.value) {
          console.log('does the change work??', ev?.target?.value);
          const parentForm = ev?.target?.closest('form');
          _this.reloadForm(parentForm);
        }
      });
  });
  }

  initEyeIcon() {
    const _this = this;
    const eyeIconClose = document.createElement('i');
    eyeIconClose.className = 'field--icon-eye js-toggle-password field--icon-eye--close field--icon-eye--open';

    document
        .querySelector('.field--input[eyeicon="true"]')?.closest('.the-form__group').append(eyeIconClose);

    document
      .querySelector('body')
      ._addCustomEventListener('click', '.js-toggle-password', _this.togglePasswordShow.bind(_this));
  }


  togglePasswordShow(e) {
    const ele = e.targetElement;
    const inputPass = ele.previousElementSibling;

    const type = inputPass.getAttribute('type') === 'password' ? 'text' : 'password';
    inputPass?.setAttribute('type', type);
    ele.classList.toggle('field--icon-eye--open');

  }

  replaceForm(htmlResponse, formClassName, currentForm = null) {
    if (htmlResponse) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlResponse, 'text/html');
      const bodyElement = doc?.childNodes[0];
      if (bodyElement) {
        // Update the cart number
        if (document.querySelector('.wb-header__account-link__cart') && bodyElement.nextSibling.querySelector('.wb-header__account-link__cart')) {
          document.querySelector('.wb-header__account-link__cart').innerHTML = bodyElement.nextSibling.querySelector('.wb-header__account-link__cart').innerHTML;
        }
        const childNode = bodyElement.nextSibling.querySelector(`.${formClassName[0]}`) ||
          bodyElement.nextSibling.querySelector('.wb-cart-products-form');
        const summaryNode = bodyElement.nextSibling.querySelector('.summary-sidebar');
        if (childNode && !formClassName.contains('wb-product-wishlist__form')) {
          const currentProjectsListElement = document.querySelector(`.${formClassName[0]}`);
          currentProjectsListElement.innerHTML = childNode.innerHTML;
          this.bindFormElement();
        }
        if (currentForm && formClassName.contains('wb-product-wishlist__form')) {
          const parent = currentForm.closest('.wb-product-wishlist');
          if (parent) {
            document.querySelector('.wb-spinner__container')?.classList?.add('d-none');
            parent.querySelector('.wb-product-wishlist__confirm-added')?.classList.remove('d-none');
            window?.setTimeout(() => {
              parent.querySelector('.wb-product-wishlist__confirm-added')?.classList.add('d-none');
            }, 500);
          }
        }


        if (summaryNode) {
          const currentSummaryNode = document.querySelector('.summary-sidebar');
          currentSummaryNode.innerHTML = summaryNode.innerHTML;
        }
      }
    }
  }
  initSpinner() {
    const requestSpinner = document.querySelectorAll('.btn--spinner');
    const spinner = document.querySelector('.wb-spinner__container');

    requestSpinner.forEach(ele => {
      ele?.addEventListener('click', (e) => {
        spinner?.classList.remove("d-none");
      });
    });
  }
}
