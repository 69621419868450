export class WbLanguage {
  constructor() {
    this.init();
  }

  init() {
    document.querySelectorAll('.language-switcher__current')?.forEach((el) => {
      el.addEventListener('click', (e) => this.languageSwitcherToggle(e));
    });
  }

  languageSwitcherToggle(e) {
    const parent = e.target ?
      e.target?.closest('.language-switcher') :
      e.closest('.language-switcher');

    const expanded = parent.getAttribute('aria-expanded') === 'false' ? false : true;
    parent?.setAttribute('aria-expanded', !expanded);
  }
}
