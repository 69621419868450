// Craft Commerce Script
export class WbPaymentForm {
  constructor() {
    this.init();
  }

  init() {
    this.billingForm();
    this.addressForm();
    this.paymentForm();
  }

  billingForm() {
    const $fieldsets = document.querySelectorAll('.js-address-fieldset');

    if ($fieldsets && $fieldsets.length) {
      $fieldsets.forEach((el) => {
        const $addressSelects = el.querySelectorAll('.js-address-select');

        if ($addressSelects && $addressSelects.length) {
          $addressSelects.forEach(function (el) {
            const $radio = el.querySelector('.js-radio input');
            const $body = el.querySelector('.js-body');

            if ($radio) {
              if ($body) {
                // Creating new address
                $radio.addEventListener('change', (ev) => {
                  if (ev.target.checked) {
                    $body.classList.remove('d-none');
                  } else {
                    $body.classList.add('d-none');
                  }
                });

                if ($radio.checked) {
                  $body.classList.remove('d-none');
                }
              } else {
                // Selecting existing address
                $radio.addEventListener('change', (ev) => {
                  if (ev.target.checked) {
                    const $newBox = document.querySelector(
                      `.js-address-select[data-model-name="${ev.target.dataset.modelName}"]`
                    );
                    $newBox?.querySelector('.js-body')?.classList.add('d-none');
                  }
                });
              }
            }
          });
        }
      });
    }

    const $billingSameAsCheckbox = document.querySelector('#billingAddressSameAsShipping');
    $billingSameAsCheckbox?.addEventListener('change', (ev) => {
      const $billingFieldSet = document.querySelector('.BillingAddressWrapper');
      const isCheckboxChecked = ev?.currentTarget?.checked;
      if (isCheckboxChecked) {
        $billingFieldSet?.classList.add('d-none');
        const children = Array.from($billingFieldSet.querySelectorAll('input, select'));
        children?.map(input => {
          input.required = false;
          return input;
        });
      } else {
        $billingFieldSet?.classList.remove('d-none');
        const children = Array.from($billingFieldSet.querySelectorAll('input, select'));
        children?.map(input => {
          input.required = true;
          return input;
        });
      }
    });
  }

  addressForm() {
    const $countrySelects = document.querySelectorAll('select.js-address-country');

    if ($countrySelects && $countrySelects.length) {
      $countrySelects.forEach((el) => {
        el.addEventListener('change', (ev) => {
          const $this = ev.target;

          // Get the value of the selected country
          const cid = $this.value;
          const $closestAddressBox = $this.closest('.js-address-box');

          if ($closestAddressBox) {
            const $states = $closestAddressBox.querySelector('select.js-address-stateId');
            const $stateName = $closestAddressBox.querySelector('input.js-address-stateName');

            if ($states && $stateName) {
              // Get all options except empty initial “Select state...” item
              const $options = $states.querySelectorAll('option:not([value=""])');

              if ($options && $options.length) {
                $options.forEach((el) => {
                  el.remove();
                });
              }

              const countryKey = 'country-' + cid;
              if (window.states.hasOwnProperty(countryKey)) {
                // We have states to select for this country, so show the states dropdown
                $states.classList.remove('d-none');
                $states.setAttribute('name', $states.dataset.modelname + '[stateValue]');

                // Hide the stateName text input since we’re using a dropdown
                $stateName.removeAttribute('name');
                $stateName.classList.add('d-none');
                $stateName.value = '';

                // Add all states as dropdown options
                for (const id in window.states[countryKey]) {
                  const state = window.states[countryKey][id];
                  const $option = document.createElement('OPTION');
                  $option.text = state;
                  $option.value = id.replace('state-', '');
                  $states.appendChild($option);
                }
              } else {
                // This country does not have a list of states, so hide the state dropdown
                $states.classList.add('d-none');
                $states.removeAttribute('name');

                // Show the stateName
                $stateName.classList.remove('d-none');
                $stateName.setAttribute('name', $stateName.dataset.modelname + '[stateValue]');
              }
            }
          }
        });
      });
    }
  }

  paymentForm() {
    const $paymentForm = document.querySelector('#paymentForm');
    // Only allow the payment form submit to be triggered once.
    $paymentForm?.addEventListener('submit', (ev) => {
      if ($paymentForm.dataset.processing) {
        ev.preventDefault();
        return false;
      }
      $paymentForm.dataset.processing = true;
    });
  }
}
