import { gsap } from 'gsap';
import { TrapFocus } from './TrapFocus';
import { throttle } from 'lodash';

export class WbUtils {
  constructor() {
    this.init();
  }

  init() {
    this.bindListeners();
    this.addResizeListener();
  }

  bindListeners() {
    this.calculateVh();
  }

  addResizeListener() {
    const _this = this;
    window.addEventListener('resize', throttle(_this.bindListeners.bind(_this), 200));
  }

  calculateVh() {
    const _this = this;
    const vh = window?.innerHeight * 0.01 || 10;
    document?.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
