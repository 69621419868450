import { gsap } from 'gsap';

Element.prototype._slideDown = function (d = 0.35) {
  return new Promise((resolve, reject) => {
    if (window.getComputedStyle(this).display !== 'none') {
      reject('Element is already visible.');
      return;
    }

    const _this = this;
    const height = window.getComputedStyle(this).height;

    gsap.set(this, { display: 'block', height: '0', overflow: 'hidden' });
    gsap.to(this, {
      height: height,
      duration: d,
      onComplete: function () {
        gsap.set(_this, { overflow: '', height: '' });
        resolve();
      },
    });
  });
};

Element.prototype._slideUp = function (d = 0.35) {
  return new Promise((resolve, reject) => {
    if (window.getComputedStyle(this).display === 'none') {
      reject("Element isn't visible.");
      return;
    }

    const _this = this;
    const height = window.getComputedStyle(this).height;

    gsap.set(this, { display: 'block', height: height, overflow: 'hidden' });
    gsap.to(this, {
      height: 0,
      duration: d,
      onComplete: function () {
        gsap.set(_this, { display: 'none', overflow: '', height: '' });
        resolve();
      },
    });
  });
};

Element.prototype._slideToggle = function (d = 0.35) {
  if (window.getComputedStyle(this).display === 'none') return this._slideDown(d);

  return this._slideUp(d);
};

Element.prototype._show = function (d = 0.35) {
  return new Promise((resolve, reject) => {
    if (window.getComputedStyle(this).display !== 'none') {
      reject('Element is already visible.');
      return;
    }

    gsap.set(this, { display: 'block', opacity: 0 });
    gsap.to(this, { opacity: 1, duration: d, onComplete: resolve });
  });
};

Element.prototype._hide = function (d = 0.35) {
  return new Promise((resolve, reject) => {
    if (window.getComputedStyle(this).display === 'none') {
      reject("Element isn't visible.");
      return;
    }

    const _this = this;

    gsap.to(this, {
      opacity: 0,
      duration: d,
      onComplete: () => {
        gsap.set(_this, { display: 'none' });
        resolve();
      },
    });
  });
};

Element.prototype._toggleAriaExpanded = function () {
  let e = this.getAttribute('aria-expanded');

  e = e == 'true' ? 'false' : 'true';
  this.setAttribute('aria-expanded', e);
};

Element.prototype._addCustomEventListener = function (event, selector, handler) {
  this.addEventListener(
    event,
    function (evt) {
      let targetElement = evt.target;

      while (targetElement != null) {
        if (targetElement.matches(selector)) {
          evt.targetElement = targetElement;
          return handler(evt);
        }
        targetElement = targetElement.parentElement;
      }
    },
    true
  );
};

Element.prototype._remove = function () {
  this.parentNode.removeChild(this);
};

Element.prototype._appendAll = function (elements) {
  const frag = document.createDocumentFragment();

  if (elements._isNodeList()) {
    elements.forEach((element) => {
      frag.appendChild(element);
    });
  } else {
    frag.appendChild(elements);
  }

  this.appendChild(frag);
};

Element.prototype._prependAll = function (elements) {
  const frag = document.createDocumentFragment();

  if (elements._isNodeList()) {
    elements.forEach((element) => {
      frag.appendChild(element);
    });
  } else {
    frag.appendChild(elements);
  }

  this.insertBefore(frag, this.firstChild);
};

Element.prototype._insertAfter = function (newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

Element.prototype._isNodeList = function () {
  return false;
};

Element.prototype._nextAll = function (selectors) {
  const nextEles = [];
  let nextEle = this;

  while (nextEle.nextElementSibling) {
    if (nextEle.nextElementSibling.matches(selectors)) {
      nextEles.push(nextEle.nextElementSibling);
    }
    nextEle = nextEle.nextElementSibling;
  }

  return nextEles;
};
