import { Modal } from 'bootstrap';

export class WbProductModal {
  constructor() {
    this.init();
  }

  init() {
      for (const btnModal of document.querySelectorAll('.wb-product__btn-modal')) {
        btnModal.addEventListener('click', (e) => {
          e.preventDefault();
        });
    }
  }
}
